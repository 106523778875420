<template>
  <div class="teacher_center">
    <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="老師申請" name="ChargeMeTribe_TeacherApply" v-if="currentUser() === null || !currentUser().role.includes('Teacher')"></el-tab-pane>
      <div v-else>
        <el-tab-pane label="課程管理" name="ChargeMeTribe_TeacherClass"></el-tab-pane>
        <el-tab-pane label="老師主頁" name="ChargeMeTribe_TeacherProfile"></el-tab-pane>
        <el-tab-pane label="老師行事曆" name="ChargeMeTribe_TeacherCalendar"></el-tab-pane>
      </div>
    </el-tabs>
  </div>
  <router-view />
</template>

<script>
export default {
  name: "TeacherCenter",
  data() {
    return {
      activeName: this.$route.name,
    };
  },
  created() {
    if (this.currentUser() === null || !this.currentUser().role.includes("Teacher")) {
      this.activeName = "ChargeMeTribe_TeacherApply";
      this.$router.push(`/ChargeMeTribe/TeacherCenter/TeacherApply`);
    } else {
      if (this.$route.name == "ChargeMeTribe_TeacherCenter") {
        this.activeName = "ChargeMeTribe_TeacherClass";
        this.$router.push(`/ChargeMeTribe/TeacherCenter/TeacherClass`);
      } else {
        this.activeName = this.$route.name;
        this.$router.push(`/ChargeMeTribe/TeacherCenter/${this.$route.name}`);
      }
    }
  },
  methods: {
    handleClick(tab) {
      this.$router.push(`/ChargeMeTribe/TeacherCenter/${tab.props.name}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher_center {
  padding: 1rem 1.5rem 0;
}
</style>
